import React from 'react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { Logout } from '@classy/campaign-page-blocks'
import * as Styled from './MemberDropdown.styled'
import { Member } from 'models/member'
import { CLASSY_LOGIN_COOKIE_NAME } from 'models/sso'
import { redirect } from 'services/sso'

const onLogout = async (originalUrl: string, baseUri: string) => {
  if (window.Classy) {
    if (window.Classy.getCookie && window.Classy.getCookie(CLASSY_LOGIN_COOKIE_NAME)) {
      const idpAccessToken = JSON.parse(
        decodeURIComponent(window.Classy.getCookie(CLASSY_LOGIN_COOKIE_NAME) || '{}'),
      )

      await fetch('/api/auth/logout')
      redirect(
        `${baseUri}/auth/logout?r=${encodeURIComponent(originalUrl)}&id_token=${idpAccessToken.id_token}`,
      )

      return
    } else {
      window.Classy.logout(async () => {
        await fetch('/api/auth/logout')
        redirect(originalUrl)
      })

      return
    }
  }
}

interface AvatarComponentProps {
  member: Member | null
}

const AvatarComponent = ({ member }: AvatarComponentProps) => {
  const { t } = useTranslation('memberDropdown')

  if (member?.thumbnail_small) {
    return (
      <Styled.ProfileImage
        src={member.thumbnail_small}
        priority
        alt={t('MemberDropdown.avatarComponent.logoutAlt', 'Logout')}
        width="36"
        height="36"
      />
    )
  }

  return (
    <Styled.ProfileButton aria-hidden="true">
      {member?.first_name?.charAt(0) ?? ''}
      {member?.last_name?.charAt(0) ?? ''}
    </Styled.ProfileButton>
  )
}

interface MemberDropdownProps {
  member: Member
  // Optional because error pages won’t have pageConfig.
  orgId?: string
  classyBaseUri?: string
}

export const MemberDropdown = ({ member, orgId, classyBaseUri }: MemberDropdownProps) => {
  const { t } = useTranslation('memberDropdown')
  const [originalUrl, setOriginalUrl] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)

  useEffect(() => {
    setOriginalUrl(window.location.href)
  }, [])

  return (
    <Styled.DropdownContainer onBlur={(e) => e.relatedTarget == null && setDropdownOpen(false)}>
      <Styled.ProfileContainer
        data-id="cp-header-profile-dropdown"
        tabIndex={0}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-label={t('MemberDropdown.profileContainer.ariaLabel', {
          defaultValue: 'User Actions Menu for {{firstName}} {{lastName}}',
          firstName: member.first_name,
          lastName: member.last_name,
        })}
        aria-haspopup="true"
        aria-expanded={dropdownOpen}
      >
        <AvatarComponent member={member} />
        <Styled.KeyboardArrowDown aria-hidden="true" />
      </Styled.ProfileContainer>
      {dropdownOpen && (
        <Styled.DropdownItems role="menu">
          <Styled.DropdownItem
            data-id="cp-header-profile-supporter-center"
            onClick={() => redirect(`${classyBaseUri}/profile${orgId ? `/${orgId}` : ''}`)}
            tabIndex={0}
            role="menuitem"
          >
            <AvatarComponent member={member} />
            {member?.first_name} {member?.last_name}
          </Styled.DropdownItem>
          {originalUrl && classyBaseUri && (
            <Styled.DropdownItem
              data-id="cp-header-logout"
              tabIndex={0}
              onClick={() => onLogout(originalUrl, classyBaseUri)}
              role="menuitem"
            >
              <Logout /> {t('MemberDropdown.actions.logout', 'Log out')}
            </Styled.DropdownItem>
          )}
        </Styled.DropdownItems>
      )}
    </Styled.DropdownContainer>
  )
}
