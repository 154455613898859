import { APIv2Fetch } from 'classy-api-proxies/apiv2'
import {
  OrganizationChannelPageResponse,
  OrganizationChannelEventResponse,
  CreateOrganizationChannelEventPayload,
} from './integrationChannels.model'
import { IncomingMessage } from 'http'

export interface GetOrganizationChannelsParams {
  organizationId: string
  req: IncomingMessage
}
/**
 * Get a list of third-party services integrated with an Organization (ie Meta CAPI).
 *
 * @see https://dev-docs.classy-test.org/apiv2/#tag/Organization-Channel/operation/listOrganizationChannel
 *
 */
export async function getOrganizationChannels({
  organizationId,
  req,
}: GetOrganizationChannelsParams): Promise<OrganizationChannelPageResponse> {
  const response = await APIv2Fetch(
    `/organizations/${organizationId}/channels?per_page=100`,
    {
      method: 'GET',
    },
    { originalRequest: req },
  )

  const channels: OrganizationChannelPageResponse = await response.json()

  return channels
}

/**
 * Post an event (ie. analytics.page, analytics.track) to a third-party service
 * integrated with the Organization (ie Meta CAPI)
 *
 * createOrganizationChannelEvent
 * @see https://dev-docs.classy-test.org/apiv2/#tag/ChannelEvent/operation/createOrganizationChannelEvent
 *
 */
export async function createChannelEvent(
  organization_id: string,
  body: CreateOrganizationChannelEventPayload,
): Promise<OrganizationChannelEventResponse> {
  const response = await fetch(`/api/organizations/${organization_id}/channel-events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  const json: OrganizationChannelEventResponse = await response.json()

  return json
}
