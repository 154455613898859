import React from 'react'

import { initializeAnalytics } from './analytics'
import type { AnalyticsServiceSettingsDto } from 'models/studio'
import type { SuperProperties } from './analytics.model'

type useLoadAnalyticsParams = SuperProperties & {
  analyticsSettings: AnalyticsServiceSettingsDto[]
  heapAppId: string
}

export const useLoadAnalytics = ({
  analyticsSettings,
  campaignId,
  environment,
  hasCartEnabled,
  heapAppId,
  orgId,
}: useLoadAnalyticsParams) => {
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    if (window.airgap && analyticsSettings.length) {
      // * analyticsSettings is resolved for both analytics and channel settings
      initializeAnalytics(
        analyticsSettings,
        {
          campaignId,
          environment,
          hasCartEnabled,
          orgId,
        },
        heapAppId,
      )

      setIsLoaded(true)
    }
  }, [analyticsSettings, campaignId, environment, hasCartEnabled, heapAppId, orgId])

  return isLoaded
}
