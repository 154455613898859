import React from 'react'
import mParticleSdk from '@mparticle/web-sdk'
import type * as MParticle from '@mparticle/web-sdk'
import { useConsentSyncing } from './useConsentSyncing'
export type MParticleInstance = typeof MParticle

interface mParticleConfig {
  appName: string
  dataPlan: { planId: string }
  identityCallback: () => void
  isDevelopmentMode: boolean
  logLevel?: 'verbose' | 'warning' | 'none'
}

interface InternalAnalyticsApi {
  mParticle?: MParticleInstance
  consentSynced: boolean
}
interface InternalAnalyticsProviderProps {
  appEnv: string
  children: React.ReactNode
  mParticleApiKey: string
}

/**
 * Context providing access to mParticle analytics instance and consent status.
 * Used internally by useInternalAnalytics hook.
 */
export const InternalAnalyticsContext = React.createContext<InternalAnalyticsApi>({
  consentSynced: false,
})

/**
 * Hook to access mParticle analytics instance and consent synchronization status.
 */
export const useInternalAnalytics = (): InternalAnalyticsApi =>
  React.useContext(InternalAnalyticsContext)

/**
 * Provider component that initializes and manages mParticle analytics integration.
 *
 * This component:
 * - Initializes mParticle SDK with the provided API key and configuration
 * - Manages mParticle user state and consent synchronization
 * - Creates a named instance ('classyInstance') to avoid conflicts
 *   with NPO's mParticle implementations
 * - Provides mParticle instance and consent status to child components via Context
 *
 * @component
 * @param {Object} props
 * @param {string} props.appEnv - Application environment ('prod', 'staging', etc.)
 * @param {string} props.mParticleApiKey - mParticle API key for initialization
 * @param {React.ReactNode} props.children - Child components that will have
 * access to the analytics context
 *
 * @example
 * ```tsx
 * <InternalAnalyticsProvider
 *   appEnv="prod"
 *   mParticleApiKey="12345"
 * >
 *   <App />
 * </InternalAnalyticsProvider>
 * ```
 */
export const InternalAnalyticsProvider: React.FC<InternalAnalyticsProviderProps> = ({
  children,
  mParticleApiKey,
  appEnv,
}) => {
  const [mParticleUser, setMParticleUser] = React.useState<MParticle.User>()
  const [mParticle, setMParticle] = React.useState<MParticleInstance>()
  const consentSynced = useConsentSyncing(mParticle, mParticleUser)

  React.useEffect(() => {
    const init = async () => {
      const mParticleConfig: mParticleConfig = {
        appName: 'whammy-web',
        dataPlan: { planId: 'web_client_data_plan' },
        identityCallback() {
          /**
           * Discovered a bug in mParticle's SDK where `result.getUser().isLoggedIn()`
           * returns `undefined` when the user is logged in, so we're working
           * around that by using the user from `sdk.Identity.getCurrentUser()`
           * (which does have the correct logged-in state)
           */
          setMParticleUser(mParticleSdk.getInstance('classyInstance').Identity.getCurrentUser())
        },
        isDevelopmentMode: appEnv !== 'prod',
        logLevel: 'verbose',
      }
      // ! We need to name the mParticle instance so that there are
      // ! no colisions with an NPO's possible usage of mParticle in a GTM or Tealium tag.
      mParticleSdk.init(mParticleApiKey, mParticleConfig, 'classyInstance')
      setMParticle(mParticleSdk?.getInstance('classyInstance') as MParticleInstance)
    }
    if (!mParticle) {
      init()
    }
  }, [appEnv, mParticleApiKey, mParticle])

  return (
    <InternalAnalyticsContext.Provider value={{ consentSynced, mParticle }}>
      {children}
    </InternalAnalyticsContext.Provider>
  )
}
