import Image from 'next/image'
import styled from '@emotion/styled'
import {
  KeyboardArrowDown as SvgKeyboardArrowDown,
  Z_INDEX_MAP,
} from '@classy/campaign-page-blocks'

export const ProfileButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--classy-color__neutral--100);
  font-family: var(--theme-font__font-family--paragraph);
  font-size: var(--classy-font__label--font-size);
  line-height: var(--classy-font__label--line-height);
  font-weight: var(--classy-font__label--font-weight);
  background-color: var(--theme-color__brand-primary, #fff);
  border: 1px solid var(--theme-color__brand-primary);
  min-height: 36px;
  min-width: 36px;
  border-radius: var(--classy-border__radius-xxl);
  cursor: pointer;
`

export const ProfileImage = styled(Image)`
  cursor: pointer;
  border-radius: var(--classy-border__radius-xxl);
`

export const DropdownContainer = styled.div`
  margin-left: auto;
  position: relative;
`

export const ProfileContainer = styled.button`
  padding: 4px;
  background-color: transparent;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  gap: 4px;
  cursor: pointer;

  &:focus-visible {
    outline: 2px dotted var(--classy-color__border--focus);
    border-radius: var(--classy-border__radius-xxl);
  }

  &:hover:not(:active) {
    background-color: var(--computed-color__brand-primary--tint);
    border-radius: var(--classy-border__radius-xxl);
  }
`

export const DropdownItems = styled.div`
  position: absolute;
  width: 296px;
  background: var(--classy-color__neutral--100);
  box-shadow: 0px 2px 4px 0px rgba(25, 29, 34, 0.1);
  z-index: ${Z_INDEX_MAP.PAGE_HEADER};
  top: 55px;
  right: -20px;
  left: inherit;
  border-radius: 8px;
  border: 1px solid var(--classy-color__neutral--90);
`

export const DropdownItem = styled.button`
  padding: 12px 16px;
  color: #1a1919;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  text-align: left;
  gap: 12px;
  font-family: var(--theme-font__font-family--paragraph);
  font-size: var(--classy-font__paragraph-md--font-siz);
  font-weight: var(--classy-font__paragraph-md--font-weight);
  line-height: var(--classy-font__paragraph-md--line-height);
  overflow: hidden;
  white-space: pre-wrap;
  width: 100%;
  background-color: transparent;
  border-top: 1px solid rgb(0 0 0 / 12%);
  border-left: none;
  border-right: none;
  border-bottom: none;

  :first-of-type {
    border: none;
  }

  :hover {
    background-color: var(--classy-color__neutral--95);
    cursor: pointer;
  }
`

export const KeyboardArrowDown = styled(SvgKeyboardArrowDown)`
  fill: var(--block-page-header__background-color--contrast);
`
