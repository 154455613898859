import { buildChannelEventObject, createChannelEvent } from '../integrationChannels'

interface metaCAPIConfig {
  orgId: string
}

/**
 * Custom Meta CAPI Analytics plugin.
 */
export const metaCapi = (config: metaCAPIConfig) => {
  const { orgId } = config

  return {
    name: 'meta-capi',
    page: ({ payload }: { payload: { event: string; properties: object } }) => {
      const channelEventObject = buildChannelEventObject('ViewContent', payload.properties)

      createChannelEvent(orgId.toString(), channelEventObject)
    },
    track: ({ payload }: { payload: { event: string; properties: object } }) => {
      const channelEventObject = buildChannelEventObject(payload.event, payload.properties)

      createChannelEvent(orgId.toString(), channelEventObject)
    },
  }
}
