import { pinoLogger } from 'utils/pino'
import { logger } from 'utils/logger'

/**
 * We'll refresh the token if it's going to expire within
 * 10 minutes (600 seconds)
 */
const ACCESS_TOKEN_EXPIRATION_THRESHOLD = 600

let classyAcessToken: string | null = null
let classyAccessTokenExpirationTime: number | null = null

let newTokenPromise: Promise<string> | null = null

interface AuthResponse {
  access_token: string
  refresh_token: string
  expires_in: number
  token_type: string
}

/**
 * The purpose of this method is to generate the access token for Classy APIs.
 * If the token exists, it will just be returned.
 *
 * Otherwise, it will be created, set to the process level context, and then returned.
 *
 * @returns token: string
 */
export const getClassyAcessToken = async (): Promise<string> => {
  /**
   * If token is being refreshed return the promise
   * for the refreshed token.
   */
  if (newTokenPromise) {
    return newTokenPromise
  }

  /**
   * If a token already exists return that.
   */
  if (classyAcessToken !== null) {
    return classyAcessToken
  }

  /**
   * Otherwise fetch a new access token and start refresher which
   * will automatically refresh the access token if it's getting close to expiration.
   */
  return refreshClassyAccessToken()
}

/**
 * Sends a request to the OAuth2 server to refresh the access token.
 * If successful, updates the saved token and expiration time and returns the new access token.
 * If an error occurs, logs an error message and re-throws the error.
 *
 * @throws Error when the request to refresh the token fails or returns an error response.
 * @returns The updated access token value.
 */
const fetchNewClassyAccessToken = async (): Promise<string> => {
  try {
    const body = JSON.stringify({
      client_id: process.env.KONG_CLIENT_ID,
      client_secret: process.env.KONG_CLIENT_SECRET,
      grant_type: 'client_credentials',
    })

    const response = await fetch(`${process.env.KONG_BASE_URI}/oauth2/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })

    if (!response.ok) {
      pinoLogger.error(
        {
          status: response.status,
          statusText: response.statusText,
          url: response.url,
        },
        `Error: Failed to fetch access token. Status code: ${response.status}`,
      )

      throw new Error(`Failed to fetch access token for client. Status code: ${response.status}`)
    }

    const data: AuthResponse = await response.json()

    // Update the saved token with the new one
    classyAcessToken = data.access_token
    classyAccessTokenExpirationTime = data.expires_in * 1000

    /**
     * Set timer that checks the remaining time until the token's expiration and
     * schedules a new token refresh if the remaining time is less than a threshold
     * defined by the `ACCESS_TOKEN_EXPIRATION_THRESHOLD` constant.
     */
    setTimeout(
      refreshClassyAccessToken,
      classyAccessTokenExpirationTime - ACCESS_TOKEN_EXPIRATION_THRESHOLD * 1000,
    )

    return classyAcessToken
  } catch (e) {
    logger('error', new Error('Failed to refresh access token', { cause: e }))

    throw e
  }
}

/**
 * Refreshes the access token and resets the token timer.
 * If a refresh is already in progress, returns the in-progress refresh promise.
 *
 * @returns The updated access token value.
 */
export const refreshClassyAccessToken = async (): Promise<string> => {
  if (newTokenPromise) {
    // If a token refresh is already in progress, return the in-progress promise.
    return newTokenPromise
  }

  try {
    // Otherwise, start a new token refresh (setting the promise globally).
    newTokenPromise = fetchNewClassyAccessToken()

    // Now await for the token
    return await newTokenPromise
  } catch (e) {
    throw e
  } finally {
    // Reset the newTokenPromise variable to null once the refresh is complete.
    newTokenPromise = null
  }
}
