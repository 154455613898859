import React from 'react'
import type * as Transcend from '@transcend-io/airgap.js-types'
import type * as MParticle from '@mparticle/web-sdk'
import { logger } from 'utils/logger'

// * Make the mParticle type usable for individual instances of the SDK
export type MParticleInstance = typeof MParticle

// * Transcend.TrackingConsent doesn't take "Auto" into account
type TrackingConsentItem = Transcend.TrackingConsent | 'Auto' | boolean

export interface ConsentChangeEvent {
  detail: {
    consent: Transcend.TrackingConsentDetails
    oldConsent: Transcend.TrackingConsentDetails
    changes: {
      [key: string]: TrackingConsentItem
    }
  }
}

interface AirgapConsent {
  purposes: { [key: string]: TrackingConsentItem }
}

export interface ConsentOptions {
  Advertising: boolean
  Analytics: boolean
  Functional: boolean
  SaleOfInfo: boolean
}

const createGDPRConsent = (mParticle: MParticleInstance, consent: boolean) =>
  mParticle.Consent.createGDPRConsent(consent, Date.now(), '', '', '')

// * This is inverted between Transcend and mParticle (so true means opted out in this case)
const createCCPAConsent = (mParticle: MParticleInstance, consent: boolean) =>
  mParticle.Consent.createCCPAConsent(!consent, Date.now(), '', '', '')

export const getNewConsentState = (mParticle: MParticleInstance, consent: ConsentOptions) => {
  const newConsent = mParticle.Consent.createConsentState()
  newConsent.addGDPRConsentState('advertising', createGDPRConsent(mParticle, consent.Advertising))
  newConsent.addGDPRConsentState('analytics', createGDPRConsent(mParticle, consent.Analytics))
  newConsent.addGDPRConsentState('functional', createGDPRConsent(mParticle, consent.Functional))
  newConsent.setCCPAConsentState(createCCPAConsent(mParticle, consent.SaleOfInfo)) // CCPA (true means opted OUT)
  return newConsent
}

/**
 * * Binds event listener to Transcend's airgap API to sync consent changes to mParticle.
 * mParticle docs: https://docs.mparticle.com/developers/sdk/web/data-privacy-controls
 * transcend docs: https://docs.transcend.io/docs/consent-management/faq
 * Copied from: https://github.com/gofundme/ssr-frontend/blob/main/apps/next-app/src/shared/context/analytics/internal-hooks.ts#L133
 */
export const useConsentSyncing = (
  mParticle?: MParticleInstance,
  mParticleUser?: MParticle.User,
) => {
  const [synced, setSynced] = React.useState(false)

  React.useEffect(() => {
    const sync = async () => {
      try {
        if (!mParticle || !mParticleUser || !window.airgap) return

        await window.airgap.sync()
        const { purposes }: AirgapConsent = window.airgap.getConsent() || {}
        const keys = Object.keys(purposes)
        const values = Object.values(purposes)

        /**
         * Transcend allows for different defaults on a regional basis.
         * If there is a disallowed consent purpose, its default value will come through as `false`
         * and if there is NOT, its value comes through as "Auto" rather than `true`.
         * mParticle expects a boolean value for each purpose, so we need to convert the
         * "Auto" values to `true` for syncing.
         * See Transcend: https://app.transcend.io/consent-manager/regional-experiences
         */
        const mappedValues: string[] = []
        if (values.includes('Auto')) {
          keys.forEach((key) => {
            if (purposes[key] === 'Auto') {
              mappedValues.push(key)
              purposes[key] = true
            }
          })
        }

        if (mappedValues.length) {
          logger('trace', "Mapped 'Auto' to 'true' for", {
            context: { mappedValues: mappedValues.join(', ') },
          })
        }

        const current = mParticleUser.getConsentState()
        const ccpa = current?.getCCPAConsentState()
        const gdpr = current?.getGDPRConsentState()
        const hasAlreadySyncedConsent =
          ccpa &&
          gdpr &&
          purposes.SaleOfInfo === !ccpa.Consented &&
          purposes.Advertising === gdpr.advertising.Consented &&
          purposes.Analytics === gdpr.analytics.Consented &&
          purposes.Functional === gdpr.functional.Consented

        if (hasAlreadySyncedConsent) {
          logger('trace', 'Consent Already Synced. \n\tTranscend: ', {
            context: { ...purposes, mParticle: { ccpa, gdpr } },
          })
          setSynced(true)
          return
        }

        const newConsent = getNewConsentState(mParticle, purposes as unknown as ConsentOptions)
        mParticleUser.setConsentState(newConsent)
        logger('trace', 'Synced consent. \n\tTranscend: ', {
          context: {
            ...purposes,
            mParticle: {
              ccpa: newConsent.getCCPAConsentState(),
              gdpr: newConsent.getGDPRConsentState(),
            },
          },
        })
      } catch (error) {
        console.error('ANALYTICS: Failed to sync consent', error)
      }
      setSynced(true)
    }

    const handleConsentChange = ({
      detail: {
        consent: { purposes },
        changes,
      },
    }: ConsentChangeEvent) => {
      if (!mParticle || !mParticleUser || !changes) return

      /**
       * Now that we have data-sync="off" on our Transcend script
       * we shouldn't see any consent-change events except those triggered
       * by the user where the values should never be set to "Auto".
       */
      if (Object.values(changes || {}).includes('Auto')) {
        console.error('ANALYTICS: Tried to change to default consent values', changes)
        return
      }

      // Fully clear previous consent state
      const previousConsentState = mParticleUser.getConsentState()
      if (previousConsentState) {
        previousConsentState.removeCCPAConsentState()
        previousConsentState.removeGDPRConsentState('advertising')
        previousConsentState.removeGDPRConsentState('analytics')
        previousConsentState.removeGDPRConsentState('functional')
      }

      // Set new consent state
      const newConsent = getNewConsentState(mParticle, {
        Advertising: !!(changes.Advertising ?? purposes.Advertising),
        Analytics: !!(changes.Analytics ?? purposes.Analytics),
        Functional: !!(changes.Functional ?? purposes.Functional),
        SaleOfInfo: !!(changes.SaleOfInfo ?? purposes.SaleOfInfo),
      })
      mParticleUser.setConsentState(newConsent)
      mParticle.logEvent('Consent Changed', mParticle.EventType.UserPreference, {
        page_url: window.location.href,
      })
      logger('trace', 'Consent Changed', {
        context: {
          ...purposes,
          mParticle: {
            ccpa: newConsent.getCCPAConsentState(),
            gdpr: newConsent.getGDPRConsentState(),
          },
        },
      })
    }

    if (mParticle && mParticleUser && !synced) {
      logger('trace', 'Device ID:', { context: { deviceId: mParticle.getDeviceId() } })
      logger('trace', 'mParticle ID:', { context: { mParticleId: mParticleUser.getMPID() } })
      mParticle.ready(async () => {
        await sync()
        setTimeout(
          () => window.airgap?.addEventListener?.('consent-change', handleConsentChange),
          100,
        )
      })
    }

    return () => {
      window.airgap?.removeEventListener?.('consent-change', handleConsentChange)
    }
  }, [mParticle, mParticleUser, synced])

  return synced
}
