import React, { ErrorInfo, ReactNode } from 'react'
import { logger } from 'utils/logger'
// * Per product request, on error we show Classy404 page (For now)
import Classy404 from 'pages/404'

type State = {
  hasError: boolean
  error: unknown
}

type Props = {
  children: ReactNode
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, error: undefined }
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    logger('fatal', new Error('Error boundary caught an error', { cause: error }), {
      context: {
        props: this.props,
        stack: info.componentStack,
      },
    })
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // * We are pointing to the Classy Designed 404 page for MVP
      return <Classy404 />
    }

    // Return children components in case of no error
    return <>{this.props.children}</>
  }
}
