import * as React from 'react'
import type { NextPage } from 'next'
import Link from 'next/link'
import Head from 'next/head'
import styled from '@emotion/styled'
import { ClassyFooter, PageHeader, OpenInNew, BREAKPOINTS } from '@classy/campaign-page-blocks'
import { MemberDropdown } from 'components/MemberDropdown'
import { SsoSdk } from 'components/SsoSdk'
import { useSsoMember } from 'hooks/useSsoMember'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

const StyledContainer = styled.div`
  background-color: var(--theme-color__background-accent);
  color: var(--theme-color__text-general);
  font-family: var(--theme-font__font-family--general);
  text-align: left;
  padding: 40px 24px;
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    padding: 64px;
    text-align: center;
  }
`

const StyledBody = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: fit-content;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    max-width: 624px;
  }

  @media (min-width: ${BREAKPOINTS.desktop}px) {
    max-width: 752px;
  }
`

const StyledHeader = styled.h1`
  align-self: stretch;
  font-size: var(--theme-font__heading-lg--font-size);
  font-weight: var(--theme-font__heading-lg--font-weight);
  line-height: var(--theme-font__heading-lg--line-height);
`
const StyledParagraph = styled.span`
  font-size: var(--theme-font__paragraph-lg--font-size);
  font-weight: var(--theme-font__paragraph-lg--font-weight);
  line-height: 32px;
`

const StyledButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  width: 100%;
  padding: 12px 24px;
  gap: 4px;
  border-radius: 8px;
  background: var(--theme-color__brand-tertiary);
  color: white;
  text-decoration: none;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    width: fit-content;
  }
`

const Classy404: NextPage = () => {
  const [isSsoSdkLoaded, setIsSsoSdkLoaded] = React.useState(false)
  const member = useSsoMember(isSsoSdkLoaded)
  const { t } = useTranslation('common')

  return (
    <>
      <Head>
        {/**
         * Safari does not support .svg favicons, so it will load /favicon.ico instead.
         * Using "sizes='any'" ensures that Chrome and Firefox will load /favicon.svg,
         * while Safari will fallback to /favicon.ico.
         *
         * https://css-tricks.com/favicons-how-to-make-sure-browsers-only-download-the-svg-version/
         */}
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/favicon.svg" />
        <title>{t('404.pageTitle', 'Page not found')}</title>
      </Head>
      <PageHeader hidePlaceholder>
        {member && <MemberDropdown member={member} />}
        <SsoSdk onReady={() => setIsSsoSdkLoaded(true)} />
      </PageHeader>
      <StyledContainer>
        <StyledBody>
          <StyledHeader>{t('404.pageHeader', 'Page not found.')}</StyledHeader>
          <StyledParagraph>
            {t(
              '404.sorryMessage',
              'Sorry, we couldn’t find this page. There may have been a typo or broken link. Please check the URL and try again.',
            )}
          </StyledParagraph>
          <StyledButton href="https://support.classy.org/s/" target="_blank">
            <OpenInNew fill="currentcolor" />
            <span>{t('404.helpCenter', 'Help center')}</span>
          </StyledButton>
        </StyledBody>
      </StyledContainer>
      <footer>
        <ClassyFooter data-id="cp-footer-login" />
      </footer>
    </>
  )
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  }
}

export default Classy404
