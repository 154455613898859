import type { AnalyticsServiceSettingsDto } from 'models/studio'
import type { OrganizationChannel } from './integrationChannels'

export const resolveAnalyticsSettings = (
  analyticsSettings: AnalyticsServiceSettingsDto[],
  organizationChannels: OrganizationChannel[],
  orgId: string,
) => {
  const orgSettings = analyticsSettings.filter(
    (setting) => setting.trackable_type === 'organization',
  )
  const campaignSettings = analyticsSettings.filter(
    (setting) => setting.trackable_type === 'campaign',
  )

  // * Transform the channels to the analytics settings format
  const transformedChannelsSettings = organizationChannels?.map((channel) => ({
    id: channel.id,
    service_name: channel.channel_name,
    parameters: channel.additional_configurations,
    trackable_id: Number(orgId),
    trackable_type: 'organization',
    tracked_events: [],
    status: channel.status,
  }))

  const resolvedAnalyticsSettings = [
    ...orgSettings.filter(
      (orgSetting) =>
        !campaignSettings.some(
          (campaignSetting) => campaignSetting.service_name === orgSetting.service_name,
        ),
    ),
    ...campaignSettings,
    ...(transformedChannelsSettings as AnalyticsServiceSettingsDto[]),
  ]

  return resolvedAnalyticsSettings
}
