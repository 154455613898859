import localFont from 'next/font/local'

import { ClassyFontFamilyDesignToken } from '@classy/campaign-page-blocks'

// * Supported Custom Font Setup
const avenir = localFont({
  src: [
    {
      path: '../../public/fonts/avenir-400.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/avenir-400-italic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../public/fonts/avenir-700.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../public/fonts/avenir-700-italic.woff2',
      weight: '700',
      style: 'italic',
    },
  ],
  preload: false,
})

export const CLASSY_CUSTOM_FONTS: Partial<Record<ClassyFontFamilyDesignToken, string>> = {
  '--classy-font__font-family--avenir': avenir.style.fontFamily,
}
