import { css, Global } from '@emotion/react'
import { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import {
  normalizeCss,
  pageResetCss,
  fullPageHeightCss,
  pageContainerCss,
  DEFAULT_THEME,
  CLASSY_DESIGN_TOKENS,
} from '@classy/campaign-page-blocks'

import { ErrorBoundary } from 'components/ErrorBoundary'
import { start } from 'utils/bugsnag'
import { CLASSY_GOOGLE_FONTS } from 'utils/googleFonts'
import { CLASSY_CUSTOM_FONTS } from 'utils/customFonts'
import { useLoadAnalytics, resolveAnalyticsSettings } from 'services/analytics'
import { ConsentProvider } from 'services/transcend'
import { InternalAnalyticsProvider } from 'services/analytics'

const globalResetCss = () => css`
  :root {
    /* Base tokens */
    ${CLASSY_DESIGN_TOKENS}
    ${DEFAULT_THEME}
    ${CLASSY_GOOGLE_FONTS}
    ${CLASSY_CUSTOM_FONTS}
  }

  ${normalizeCss}
  ${pageResetCss}
  ${fullPageHeightCss}

  body {
    font-family: var(--classy-font__font-family--base);
    ${pageContainerCss}
  }

  /**
   * In some cases, a tracking service adds a non-hidden image to the end of the <body>, which
   * causes double scroll bars. The double scroll bars occurs because html, body, and div#__next
   * have a height of 100% (see fullPageHeightCss). When an image is added after div#__next, the
   * body is forced to be larger than height 100%, triggering an extra scroll bar.
   *
   * For now, only targeting direct children of body.
   */
  body > img {
    display: block;
    height: 0;
  }
`

// TODO: CL-47302: Remove logic with mparticle feature flag
const IsMParticleEnabledSwitch = ({
  airgapSrcUrl,
  appEnv,
  mParticleApiKey,
  isMParticleEnabled,
  children,
}: {
  airgapSrcUrl: string
  appEnv: string
  mParticleApiKey: string
  isMParticleEnabled: boolean
  children: React.ReactNode
}) =>
  isMParticleEnabled && mParticleApiKey ? (
    <InternalAnalyticsProvider appEnv={appEnv} mParticleApiKey={mParticleApiKey}>
      {children}
    </InternalAnalyticsProvider>
  ) : (
    <ConsentProvider airgapSrc={airgapSrcUrl}>{children}</ConsentProvider>
  )

const App = ({ Component, pageProps }: AppProps) => {
  // FYI, pageProps is initially empty, hence why ?. is used for all properties
  const { __ENV, pageConfig } = pageProps

  // Initialize Bugsnag
  start(__ENV?.bugsnagApiKey, __ENV?.appEnv, __ENV?.nextBuildId)

  const campaignId = pageConfig?.campaignId
  const orgId = pageConfig?.orgId

  /**
   * resolveAnalyticsSettings filters and/or transforms analyticsServiceSettings
   * and organizationChannels data, and returns an array of objects combining
   * analyticsServiceSettings and organizationChannels.
   *
   * If analyticsServiceSettings and organizationChannels are both false, resolveAnalyticsSettings
   * will return an empty array.
   */
  const analyticsSettings = resolveAnalyticsSettings(
    pageConfig?.analyticsServiceSettings ?? [],
    pageConfig?.organizationChannels ?? [],
    orgId,
  )

  const isAnalyticsLoaded = useLoadAnalytics({
    analyticsSettings: analyticsSettings,
    environment: __ENV?.appEnv,
    campaignId,
    orgId,
    hasCartEnabled: pageConfig?.isCartEnabled || false,
    heapAppId: __ENV?.heapAppId,
  })

  // TODO: CL-47302: Remove `IsMParticleEnabledSwitch` logic with mparticle feature flag
  return (
    <IsMParticleEnabledSwitch
      airgapSrcUrl={__ENV?.airgapSrcUrl}
      appEnv={__ENV?.appEnv}
      mParticleApiKey={__ENV?.mParticleApiKey}
      isMParticleEnabled={__ENV?.isMParticleEnabled}
    >
      <Global styles={globalResetCss()} />
      <ErrorBoundary>
        <Component {...{ ...pageProps, isAnalyticsLoaded }} />
      </ErrorBoundary>
    </IsMParticleEnabledSwitch>
  )
}

export default appWithTranslation(App)
