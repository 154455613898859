import type { SessionOptions } from 'iron-session'
import type { Member } from 'models/member'

export const WHAMMY_SESSION_COOKIE_NAME = 'whammy-session'

export const sessionOptions: SessionOptions = {
  password: process.env.SESSION_SECRET ?? '',
  cookieName: WHAMMY_SESSION_COOKIE_NAME,
  ttl: 25000,
  cookieOptions: {
    httpOnly: false,
  },
}

// This is where we specify the typings of req.session.*
declare module 'iron-session' {
  interface IronSessionData {
    kong_api_token?: {
      refresh_token: string
      token_type: string
      access_token: string
      expires_in: number
      //stored in milliseconds UTC
      expires_at: number
    }
    member?: Member
    state?: string
  }
}

export interface ClassyLoginCookie {
  access_token: string
  id_token: string
  refresh_token: string
  expires_in: number
  scopes: string
  //stored in milliseconds UTC
  expires_at: number
}
