import { getCookieValue } from 'utils/getCookieValue'
import { CreateOrganizationChannelEventPayload } from './integrationChannels.model'

/**
 * The buildChannelEventObject transforms analytics data to be sent to APIv2:
 *
 * @see https://docs.classy-test.org/apiv2/#tag/ChannelEvent/operation/createOrganizationChannelEvent
 *
 * Currently all Meta CAPI events: 'ViewContent' and 'AddToCart' share this interface,
 * and it is expected to grow over time. Eventually we’ll want to find a way to break it up better.
 */

interface ChannelEventData {
  content_name?: string
  donation_amount?: number
  currency?: string
  title?: string
  items?: number
  donation_frequency?: string
  email?: string
  first_name?: string
  last_name?: string
  client_ip?: string
  program_designation_name?: string
  program_designation_id?: string
  end_date?: Date | null
  event_id?: string
  event_time?: number
}

export const buildChannelEventObject = (event: string, data?: ChannelEventData) => {
  const channelEventObject = {
    member_id: null,
    event: {
      event_name: event,
      facebook_browser_id: getCookieValue('_fbp'),
      facebook_click_id: getCookieValue('_fbc'),
      event_time: data?.event_time,
      event_id: data?.event_id,
      event_source_url:
        window.location.origin +
        window.location.pathname +
        window.location.search +
        window.location.hash,
      action_source: 'website',
      client_user_agent: navigator.userAgent,
      meta_test_event_code: getCookieValue('cl_meta_event_code'),
    } as CreateOrganizationChannelEventPayload['event'],
  }

  switch (event) {
    case 'ViewContent':
      channelEventObject.event.meta_event_name = 'ViewContent'
      channelEventObject.event.content_name = `${data?.title}: Landing`

      channelEventObject.event.content_url =
        window.location.origin +
        window.location.pathname +
        window.location.search +
        window.location.hash

      channelEventObject.event.email = data?.email ?? ''
      channelEventObject.event.first_name = data?.first_name ?? ''
      channelEventObject.event.last_name = data?.last_name ?? ''
      channelEventObject.event.client_ip = data?.client_ip ?? ''
      break

    case 'AddToCart':
      channelEventObject.event.meta_event_name = 'AddToCart'
      channelEventObject.event.content_name = data?.content_name
      channelEventObject.event.value = data?.donation_amount ?? 0
      channelEventObject.event.currency = data?.currency ?? 'USD'
      channelEventObject.event.items = data?.items ?? 0
      channelEventObject.event.donation_frequency = data?.donation_frequency ?? ''

      channelEventObject.event.email = data?.email ?? ''
      channelEventObject.event.first_name = data?.first_name ?? ''
      channelEventObject.event.last_name = data?.last_name ?? ''
      channelEventObject.event.client_ip = data?.client_ip ?? ''

      channelEventObject.event.program_designation_name = data?.program_designation_name ?? ''
      channelEventObject.event.program_designation_id = data?.program_designation_id ?? ''
      channelEventObject.event.end_date = data?.end_date ?? null
    default:
      break
  }

  return channelEventObject
}
