/**
 * getCookieValue is copied from Checkout team codebase
 * It returns the cookie value for a cookie name from document.cookie.
 *
 * @param {string} name The cookie name.
 * @returns {string | undefined} The value for the cookie name as a string
 * or undefined if the cookie name is not found.
 */

export const getCookieValue = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length === 2) {
    const cookieValue = parts.pop()

    if (cookieValue) {
      return cookieValue.split(';').shift()
    }
  }

  return undefined
}
