/**
 * While GoogleTagManager accepts nested objects, Tealium and GA4 do not.
 * To effectively send analytic events to Tealium and GA4, we need to
 * flatten the data layer object into a format that is compatible with these analytics platforms.
 *
 * GA4
 * - Nested objects should be transformed into flat objects with the keys separated by an underscore
 * - Arrays of objects (e.g. {items: [{price: 2 }]}) do not need to be flattened
 *
 * Tealium
 * - boolean values should be replaced by the strings “0” and “1”
 * - Any numbers (integers, floats, etc) should be transformed into strings
 * - Nested objects should be transformed into flat objects with the keys separated by an underscore
 * - Arrays of objects should be transformed so that each key
 *   within the object becomes a top-level key whose value is
 *   an array of all values across each of the objects inside
 *   the array (with undefined for values that don’t exist)
 *
 * Note: Per current product requirements, we assume we won’t ever
 * have to deal with nested objects inside of arrays or objects.
 */

export const flattenObjectForTealium = (
  dataObject: Record<string, unknown>,
  prefix: string = '',
  result: Record<string, string | object> = {},
): Record<string, unknown> => {
  if (!dataObject) return {}

  // Iterate over each key-value pair in dataObject
  Object.entries(dataObject).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        // Check if the array contains any objects
        if (value.some((item) => typeof item === 'object' && item !== null)) {
          /**
           * Create a set of all unique keys present in the objects within the array.
           * We need to know all the keys before hand so
           * we can account for undefined keys in some objects.
           * For example, if we have:
           * {
           *  items: [
           *    { name : 'T-shirt', size 'M'},
           *    { name: 'Hat', color: 'blue' }
           *  ]
           * }
           * we would flatten that into
           * {
           *  items_name: ['T-Shirt', 'Hat'],
           *  items_size: ['M', undefined],
           *  items_color: [undefined, 'black']
           * }
           */
          const allKeys = new Set(value.flatMap((item) => Object.keys(item || {})))
          // For each key found within the array objects, create a new entry in the result object
          allKeys.forEach((innerKey) => {
            result[`${prefix}${key}_${innerKey}`] = value.map((item) => {
              // If the item is null or the key doesn't exist, return undefined
              if (!item || item[innerKey] === undefined || item[innerKey] === null) return undefined
              // If the value is a boolean, convert it to '1' or '0'
              if (typeof item[innerKey] === 'boolean') return item[innerKey] ? '1' : '0'
              // Otherwise, convert the value to a string
              return String(item[innerKey])
            })
          })
        } else {
          /**
           * If the array doesn't contain any objects, assign the array as-is to the result object.
           * For example, { campaign: { features: ["cart"] } }
           * would flatten to: { campaign_features: ["cart"] }
           */
          result[`${prefix}${key}`] = value
        }
      } else {
        // If the value is an object, recursively flatten the object
        flattenObjectForTealium(
          value as Record<string, unknown>,
          `${prefix}${key}_`, // Append the current key to the prefix
          result, // Pass the result object to collect the flattened key-value pairs
        )
      }
    } else if (value !== undefined && value !== null) {
      // Convert boolean values to '1' or '0' and other types to strings
      result[`${prefix}${key}`] = typeof value === 'boolean' ? (value ? '1' : '0') : String(value)
    }
  })

  return result
}

/**
 * Function to flatten a nested objected for GA4.
 */
export const flattenObjectForGA4 = (
  dataObject: Record<string, unknown>,
): Record<string, unknown> => {
  const result: Record<string, unknown> = {}

  for (const key in dataObject) {
    if (typeof dataObject[key] === 'object' && !Array.isArray(dataObject[key])) {
      const nestedObject = dataObject[key] as Record<string, unknown>
      /**
       * Flatten the object by appending nested keys to the current key.
       * For example, { campaign: { name: 'Annual Giving' } }
       * would flatten to: { campaign_name: 'Annual Giving' }
       */
      for (const nestedKey in nestedObject) {
        const nestedValue = nestedObject[nestedKey]
        /**
         * If the nested value is null, set it to undefined.
         * Otherwise directly assign the flattened key-value pair to the result object.
         */
        result[`${key}_${nestedKey}`] = nestedValue === null ? undefined : nestedValue
      }
    } else {
      // Copy non-object values directly to the result object
      result[key] = dataObject[key]
    }
  }

  return result
}
