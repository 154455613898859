import type { AnalyticsPlugin } from 'analytics'
import type { PluginData, SuperProperties } from '../analytics.model'

type PixelSettings = SuperProperties & {
  pixelId: string
}

type PixelPluginData = PluginData & {
  config: PixelSettings
}

/**
 * * Custom Facebook Pixel Analytics plugin.
 */
export const facebookPixel = (settings: Partial<PixelSettings>): AnalyticsPlugin => ({
  name: 'facebook-pixel',
  config: settings,
  initialize: ({ config }: PixelPluginData) => {
    if (!!window.fbq) return

    //  * Init Facebook Pixel Script
    const pixelScript = document.createElement('script')
    pixelScript.id = 'classy-facebook-pixel'
    pixelScript.type = 'text/javascript'
    pixelScript.async = true
    pixelScript.text = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${config.pixelId});
    `

    // * Inject Facebook Pixel script into the DOM
    document.body.prepend(pixelScript)
  },
  page: ({ payload }: PixelPluginData): void => {
    window.fbq('track', 'PageView', {}, { eventID: payload.properties.event_id })
  },
  track: ({ payload }: PixelPluginData) => {
    window.fbq(
      'track',
      payload.event,
      { ...payload.properties },
      { eventID: payload.properties.event_id },
    )
  },
  loaded: (): boolean => {
    return !!window.fbq
  },
})
