import {
  Domine,
  Inter,
  Karla,
  Lato,
  Libre_Caslon_Display,
  Libre_Caslon_Text,
  Merriweather,
  Montserrat,
  Noto_Sans,
  Nunito,
  Nunito_Sans,
  Open_Sans,
  Oswald,
  Outfit,
  Playfair_Display,
  Poppins,
  PT_Sans,
  Raleway,
  Roboto,
  Roboto_Condensed,
  Slabo_13px,
  Slabo_27px,
  Source_Sans_3,
  Source_Serif_4,
  Work_Sans,
} from 'next/font/google'
import { ClassyFontFamilyDesignToken } from '@classy/campaign-page-blocks'

// * Supported Google Font Setup
const domine = Domine({
  subsets: ['latin'],
  weight: ['400', '500', '700'],
  style: ['normal'],
  preload: false,
})
const inter = Inter({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const karla = Karla({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const lato = Lato({
  subsets: ['latin'],
  weight: ['300', '400', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const libreCaslonDisplay = Libre_Caslon_Display({
  subsets: ['latin'],
  weight: ['400'],
  style: ['normal'],
  preload: false,
})
const libreCaslonText = Libre_Caslon_Text({
  subsets: ['latin'],
  weight: ['400', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const merriweather = Merriweather({
  subsets: ['latin'],
  weight: ['300', '400', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const notoSans = Noto_Sans({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const nunito = Nunito({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const nunitoSans = Nunito_Sans({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const openSans = Open_Sans({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const oswald = Oswald({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal'],
  preload: false,
})
const outfit = Outfit({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal'],
  preload: false,
})
const playfairDisplay = Playfair_Display({
  subsets: ['latin'],
  weight: ['400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const poppins = Poppins({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const ptSans = PT_Sans({
  subsets: ['latin'],
  weight: ['400', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const raleway = Raleway({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const roboto = Roboto({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const robotoCondensed = Roboto_Condensed({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const slabo13px = Slabo_13px({
  subsets: ['latin'],
  weight: '400',
  style: 'normal',
  preload: false,
})
const slabo27px = Slabo_27px({
  subsets: ['latin'],
  weight: '400',
  style: 'normal',
  preload: false,
})
const sourceSans3 = Source_Sans_3({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const sourceSerif4 = Source_Serif_4({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})
const workSans = Work_Sans({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  preload: false,
})

// Map of supported Google fonts as CSS Custom Properties
export const CLASSY_GOOGLE_FONTS: Partial<Record<ClassyFontFamilyDesignToken, string>> = {
  '--classy-font__font-family--domine': domine.style.fontFamily,
  '--classy-font__font-family--inter': inter.style.fontFamily,
  '--classy-font__font-family--karla': karla.style.fontFamily,
  '--classy-font__font-family--lato': lato.style.fontFamily,
  '--classy-font__font-family--libre-caslon-display': libreCaslonDisplay.style.fontFamily,
  '--classy-font__font-family--libre-caslon-text': libreCaslonText.style.fontFamily,
  '--classy-font__font-family--merriweather': merriweather.style.fontFamily,
  '--classy-font__font-family--montserrat': montserrat.style.fontFamily,
  '--classy-font__font-family--noto-sans': notoSans.style.fontFamily,
  '--classy-font__font-family--nunito': nunito.style.fontFamily,
  '--classy-font__font-family--nunito-sans': nunitoSans.style.fontFamily,
  '--classy-font__font-family--open-sans': openSans.style.fontFamily,
  '--classy-font__font-family--oswald': oswald.style.fontFamily,
  '--classy-font__font-family--outfit': outfit.style.fontFamily,
  '--classy-font__font-family--playfair-display': playfairDisplay.style.fontFamily,
  '--classy-font__font-family--poppins': poppins.style.fontFamily,
  '--classy-font__font-family--pt-sans': ptSans.style.fontFamily,
  '--classy-font__font-family--raleway': raleway.style.fontFamily,
  '--classy-font__font-family--roboto': roboto.style.fontFamily,
  '--classy-font__font-family--roboto-condensed': robotoCondensed.style.fontFamily,
  '--classy-font__font-family--slabo-13px': slabo13px.style.fontFamily,
  '--classy-font__font-family--slabo-27px': slabo27px.style.fontFamily,
  '--classy-font__font-family--source-sans-3': sourceSans3.style.fontFamily,
  '--classy-font__font-family--source-serif-4': sourceSerif4.style.fontFamily,
  '--classy-font__font-family--work-sans': workSans.style.fontFamily,
}
