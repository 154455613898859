export interface SsoStatus {
  id?: number
  is_logged_in: boolean
  has_authorized: boolean
}

export interface ClassySdk {
  ready: (callback: () => void) => void
  status: (callback: (response: SsoStatus) => void, accessToken?: string | undefined) => void
  parseDom: (callback: (response: { authorization_code?: string }) => void) => void
  logout: (callback: () => void) => void
  // only available on ClassyLogin SDK, not SSO SDK
  getCookie?: (name: string) => string | undefined
}

export const CLASSY_LOGIN_DEFAULT_SCOPES =
  'organization:read,organization:write,member:read,member:write,api-app:read,api-app:write,me,offline_access,openid,service-provider:read,service-provider:write,asset:read,asset:write'

export const CLASSY_LOGIN_COOKIE_NAME = 'whammy_accessToken'
